import React, { useState, useEffect } from 'react';
import { Link } from "gatsby";
import { Bars2Icon } from '@heroicons/react/24/solid';

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  // Function to handle the Esc key event
  const handleKeyDown = (e) => {
    if (e.keyCode === 27) setIsNavOpen(false);
  };

  // Debounce function to limit the number of event calls
  const debounce = (func, wait = 10, immediate = true) => {
    let timeout;
    return function executedFunction(...args) {
      const context = this;
      const later = () => {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };
  

  // Function to check scroll position
  const checkScroll = () => {
    const scrolled = window.scrollY > window.innerHeight * 0.3;
    if (scrolled) {
      document.querySelector('nav').classList.add('fixed-nav');
    } else {
      document.querySelector('nav').classList.remove('fixed-nav');
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('scroll', debounce(checkScroll));

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('scroll', debounce(checkScroll));
    };
  }, []);

  return (
    <nav className="bg-gray-900 text-white border-b border-gray-800 md:px-5 relative w-full bg-opacity-90 z-50 transition-all duration-500 ease-in-out">
    
      <div className="container mx-auto">
        <div className="flex justify-between h-16 px-5 mx-auto md:px-0">
          
          
          {/* Logo */}
          <div className="flex-shrink-0 flex items-center">
            <h1 className="text-4xl md:text-4xl uppercase filter-invert font-bold">
              <Link to="/" itemProp="url">Crewzip</Link>
            </h1>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex w-full md:w-auto gap-8 items-center justify-center ml-6">
            <Link to="/profiles" className="px-3 py-2 text-sm font-medium hover:text-gray-300">Profiles</Link>
            <Link to="/search" className="px-3 py-2 text-sm font-medium hover:text-gray-300">Search</Link>
            <Link to="/about" className="px-3 py-2 text-sm font-medium text-white hover:text-gray-300">Who we are</Link>
          </div>

          {/* Mobile Menu Icon */}
          <div
            className="md:hidden flex gap-8 items-center justify-center z-50"
            onClick={() => setIsNavOpen((prev) => !prev)}
          >
            <Bars2Icon className="h-10 w-10 flex-shrink-0 text-gray-400" aria-hidden="true" />
          </div>
        </div>

        {/* Mobile Menu */}
        {isNavOpen && (
          <div className="flex justify-center flex-col px-20 text-center gap-5 bg-gray-900 bg-opacity-75 backdrop-blur-md text-white py-4 h-screen w-full fixed top-0 z-40">
            <Link to="/profiles" className="py-2 text-lg font-medium hover:text-gray-300 uppercase" onClick={() => setIsNavOpen(false)}>Profiles</Link>
            <Link to="/search" className="py-2 text-lg font-medium hover:text-gray-300 uppercase" onClick={() => setIsNavOpen(false)}>Search</Link>
            <Link to="/about" className="py-2 text-lg font-medium text-white hover:text-gray-300 uppercase" onClick={() => setIsNavOpen(false)}>Who we are</Link>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
