import * as React from "react"
import { graphql } from "gatsby"
import HeroImg from "../images/production-set-3.png"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PrivacyPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <div className="container mx-auto text-white">
        <div className="p-5 lg:py-10">
          <div className="flex flex-col justify-between">
            <div className="container mx-auto px-4 py-8">
              <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
              
              <div className="text-sm">
                <p className="mb-4">Last Updated: December 19, 2023</p>
                <p className="mb-4">CREWZIP (CREWZIP, "us", "our" or "we") who owns and controls the website found at https://www.crewzip.com/ has developed this Privacy Policy which details important information regarding the use and disclosure of user information collected on https://www.crewzip.com, and any successor websites, (collectively, the “Site”), and other interactive properties, including but not limited to our mobile applications and mobile-focused websites (the Site and our other interactive properties are collectively referred to herein as the “Applications"). This Privacy Policy also applies to our offline collection of information. We provide this Privacy Policy to help you make an informed decision about whether to use or continue to use the services provided by us ("Services").</p>
                <p className="mb-4">This Privacy Policy is incorporated into and subject to our Terms of Use (“Terms”). Each time that you access Applications or our Services you signify that you agree to be bound by the Terms and, this Privacy Policy, and acknowledge that we are not providing you with a consumer report, and agree that you will not use the information obtained from us for any purpose covered by the Fair Credit Reporting Act (15 U.S.C. §1681, et seq.). If you do not agree to the Terms of Use and this Privacy Policy, you must discontinue using the Applications or Services.</p>

                <h2 className="text-lg font-bold mb-4">Your Privacy and the California Consumer Privacy Act (CCPA)</h2>
                <p className="mb-4">
                  At CREWZIP, your privacy is of utmost importance to us. This Privacy Policy is here to inform you about how we handle your data in compliance with the California Consumer Privacy Act (CCPA) and other applicable privacy laws.
                </p>

                <p className="mb-4">Please note, we have no control over public records, and do not guarantee or warrant that a request for removal of or change to personal information as described above will result in removal of or change to all of your information from our Applications.</p>
                <p className="mb-4"><strong>Opt-Out:</strong> You have the right to opt out of the sale of your personal information to third parties. From January 1, {new Date().getFullYear()}, you can exercise this right through the "Do Not Sell My Personal Information" link in the footer of our Applications. We do not sell the personal information of minors. Please note that a request to opt-out is, in effect, a request to have your profile removed from the Services.</p>
                <p className="mb-4"><strong>Non-Discrimination:</strong> You have the right to not be discriminated against for exercising any of these rights.</p>

                <p className="mb-4">CCPA Request Handling: Below you will find information related to the CCPA requests received and handled by CREWZIP.com, as per section 999.317 of the CCPA. This data corresponds to the time period between January 1, {new Date().getFullYear()} and December 31, {new Date().getFullYear()}.</p>
                {/* <p className="my-4">
                <table className="md:min-w-5xl max-w-5xl divide-y divide-gray-300 border border-gray-300">
                    <tbody className="">
                        <tr className="border border-gray-300">
                            <th className="py-3 px-4 text-left text-sm font-semibold text-white">Request Type</th>
                            <th className="py-3 px-4 text-left text-sm font-semibold text-white">Requests Received</th>
                            <th className="py-3 px-4 text-left text-sm font-semibold text-white">Requests Complied</th>
                            <th className="py-3 px-4 text-left text-sm font-semibold text-white">Average Reply Time</th>
                        </tr>
                        <tr className="border border-gray-300">
                            <td className="whitespace-nowrap px-4 py-4 text-sm text-white">Opt-Out of Sale</td>
                            <td className="whitespace-nowrap px-4 py-4 text-sm text-white">225617</td>
                            <td className="whitespace-nowrap px-4 py-4 text-sm text-white">225380</td>
                            <td className="whitespace-nowrap px-4 py-4 text-sm text-white">11 days</td>
                        </tr>
                    </tbody>
                </table>
                </p> */}

                <p className="mb-4"><strong>Your California Privacy Rights:</strong> California Civil Code Section 1798.83 permits visitors to our Applications who are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. We do not share personal information with third parties for their direct marketing purposes.</p>
                <p className="mb-4"><strong>Do Not Track:</strong> At this time, our Site does not recognize automated browser signals regarding tracking mechanisms, which may include “Do Not Track” instructions.</p>
                <p className="mb-4"><strong>Children's Privacy:</strong> The Applications are intended for a general audience and are not directed or intended for children under 18 years of age. We do not knowingly collect personal information from individuals under 18 years of age. If we later obtain actual knowledge that a user is under 18 years of age, we will remove that user’s personal information from our systems.</p>
                <p className="mb-4"><strong>Changes to the Privacy Policy:</strong> We may change this Privacy Policy from time to time. The most recent version of the Privacy Policy will always be posted on our Site, with the "Last Updated" date posted at the top. We may revise and update this Privacy Policy if our practices change, as technology changes, or as we add new Applications or change existing ones. If we make any material changes to our Privacy Policy or how we handle your personal information, you will know because the Last Updated date of the Privacy Policy will change. If we are going to use any personal information in a manner that is materially different from that stated in our Privacy Policy at the time we collected such information, we will give you a reasonable opportunity to consent to the change. If you do not consent, your personal information will be used as agreed to under the terms of the Privacy Policy in effect at the time we obtained that information. By accessing the Applications after the Last Updated date, you are deemed to consent to our then-current Privacy Policy. We will use information previously obtained in accordance with the Privacy Policy in effect when the information was obtained from you.</p>
                <p className="mb-4"><strong>Disputes:</strong> If you choose to visit the Applications your visit and any dispute over privacy is subject to this Privacy Policy and our Terms of Use including limitations on damages, resolution of disputes, and application of the laws of the United States, and the State of California.</p>
                <p className="mb-4"><strong>No Rights of Third Parties:</strong> This Privacy Policy does not create rights enforceable by third parties or require disclosure of any personal information relating to users of the Applications.</p>
                
                <h2 className="text-md font-semibold mb-4">What Information Do We Collect?</h2>
                <p className="mb-4">
                  We collect publicly available professional profiles for professional networking and job opportunities, including names, titles, and other professional details.
                </p>

                <h2 className="text-md font-semibold mb-4">Your Rights Under the CCPA</h2>
                <p className="mb-4">
                  As a California resident, you are granted specific rights regarding your personal information:
                </p>
                <ul className="list-disc pl-5 mb-4">
                  <li><strong>The Right to Know:</strong> You can know what personal information is collected, used, shared, and sold.</li>
                  <li><strong>The Right to Delete:</strong> You may request the deletion of your personal information from our records.</li>
                  <li><strong>The Right to Opt-Out:</strong> You can opt-out of the sale of your personal information. Currently, we do not sell personal information.</li>
                  <li><strong>The Right to Non-Discrimination:</strong> Exercising your CCPA rights will not lead to any form of discrimination by us.</li>
                </ul>

                <h2 className="text-md font-semibold mb-4">Exercising Your Rights</h2>
                <p className="mb-4">
                  To exercise your CCPA rights, please reach out to us at <a href="mailto:swap.yip0r@icloud.com">swap.yip0r@icloud.com</a>. We will address your request in compliance with the CCPA.
                </p>

                <h2 className="text-md font-semibold mb-4">Accessibility Policy and Commitment Statement.</h2>
                <ul className="list-disc pl-5 mb-4">
                    <li>CREWZIP is committed to interacting, communicating and providing goods and services to all customers and members of the public that are based on the full inclusion of all persons with disabilities. We recognize people with disabilities may have different needs and we will, in accordance with government guidelines, support the identification, removal and prevention of barriers for persons with disabilities. Our goal is to deliver an excellent user experience for all types of viewers, using different types of mobile and desktop devices, and those using assistive technology. Our efforts to improve and optimize the user experience for accessibility are ongoing as we develop new areas of our website, and as technology changes. If at any point in your browsing experience you have feedback for us to improve our user experience or accessibility support, please contact us at  <a href="mailto:swap.yip0r@icloud.com">swap.yip0r@icloud.com</a>. If you wish to provide CREWZIP with feedback regarding the manner in which we provided our service, please choose one of the feedback options below that best addresses your needs.</li>
                    <li>To send us an email: <a href="mailto:swap.yip0r@icloud.com">swap.yip0r@icloud.com</a></li>
                </ul>
                <p className="mb-4">To contact us by mail, please address your correspondence as follows:</p>

                <h2 className="text-md font-semibold mb-4">Changes to This Policy</h2>
                <p className="mb-4">
                  We may update this Privacy Policy to reflect changes in our practices or relevant laws. We encourage you to review this policy periodically for any updates.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="Privacy Policy" />

export default PrivacyPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
